import React, { memo } from "react";
import { useObserver } from "mobx-react-lite";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "../containers/login/login";
import { ProjectSelection } from "../containers/projectSelection/ProjectSelection";
import Logout from "../containers/logout/Logout";
import PageNotFound from "../pages/pageNotFound/PageNotFound";
import LandingPage from "../pages/landingPage/LandingPage";
import FullScreenPage from "../pages/fullScreenPage/FullScreenPage";
import NavigationPage from "../pages/menuNavigationPage/NavigationPage";
import PowerBIPage from "../pages/powerBiPage/PowerBIPage";
import PowerBI from "../containers/powerbi/PowerBI";
// * Unit Info Components
import OldUnitInfoPage from "../pages/unitInfoPage/OldUnitInfoPage";
import UnitInfoPage from "../pages/unitInfoPage/UnitInfoPage";
// * Activity Info Components
import ActivityInfoPage from "../pages/activityInfoPage/ActivityInfoPage";
import ExportSnagsPage from "../pages/exportSnagsPage/ExportSnagsPage";
import ExportTasksPage from "../pages/exportTasksPage/ExportTasksPage";
import PasswordChange from "../pages/passwordChange/PasswordChange";
import ChecklistReportsPage from "../pages/checklistReportsPage/ChecklistReportsPage";
import FormDataAggregate from "../pages/form-data-aggregate/FormDataAggregate";
import FormDataWritePage from "../pages/formDataWritePage/FormDataWritePage";
import RequestsModule from "../pages/requestsModulePage/RequestsModule";
import AdminLandingPage from "../pages/admin/landing/LandingPage";

const MainRouter = () => {
  return useObserver(() => (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/" render={() => <Redirect to="/menu" />} />
        <PrivateRoute exact path="/changePassword" section="home" screen="misc" component={PasswordChange} />
        <PrivateRoute exact path="/projects" section="projects" screen="misc" component={ProjectSelection} />
        <PrivateRoute exact path="/menu" section="home" screen="landing" component={NavigationPage} scaffold />
        <PrivateRoute exact path="/menu/:phase/:spaceType" section="home" screen="landing" component={NavigationPage} scaffold />
        <PrivateRoute exact path="/dashboard/:phase/:spaceType/landing/:tab?/:graphType?" section="dashboard" screen="landing" component={LandingPage} scaffold infoPanel />
        <PrivateRoute exact path="/dashboard/:phase/tower/fullscreen/:tower?/:graphType?" section="dashboard" spaceType="tower" screen="fullscreen" component={FullScreenPage} scaffold infoPanel />
        <PrivateRoute exact path="/dashboard/:phase/:spaceType/fullscreen/:tab?/:tower?/:unitType?/:graphType?" section="dashboard" screen="fullscreen" component={FullScreenPage} scaffold infoPanel />
        <PrivateRoute exact path="/activityInfo/:unitActivity" section="legacy" screen="misc" graphType="table" component={ActivityInfoPage} scaffold infoPanel />
        <PrivateRoute exact path="/unitInfo/:phase/:tower?/:activity?/:unit?" section="legacy" screen="fullscreen" graphType="table" spaceType="tower" component={OldUnitInfoPage} scaffold infoPanel />
        <PrivateRoute exact path="/snags" section="snags" screen="landing" graphType="table" component={ExportSnagsPage} scaffold infoPanel />
        <PrivateRoute exact path="/snags/:phase/:spaceType" section="snags" screen="landing" graphType="table" component={ExportSnagsPage} scaffold infoPanel />
        <PrivateRoute exact path="/snags/:phase/:spaceType/:tower" section="snags" screen="fullscreen" graphType="table" component={ExportSnagsPage} scaffold infoPanel />
        <PrivateRoute exact path="/tasks" section="tasks" screen="landing" graphType="table" component={ExportTasksPage} scaffold infoPanel />
        <PrivateRoute exact path="/tasks/:phase/:spaceType" section="tasks" screen="landing" graphType="table" component={ExportTasksPage} scaffold infoPanel />
        <PrivateRoute exact path="/tasks/:phase/:spaceType/:tower" section="tasks" screen="fullscreen" graphType="table" component={ExportTasksPage} scaffold infoPanel />
        <PrivateRoute exact path="/powerbi" section="powerbi" screen="powerBI" graphType="powerbi" component={PowerBIPage} scaffold />
        <PrivateRoute exact path="/powerbi/:groupId/:reportId" section="powerbi" screen="powerBI" graphType="powerbi" component={PowerBI} scaffold />
        <PrivateRoute exact path="/unit-act-info" section="unit-act-info" screen="landing" graphType="table" component={UnitInfoPage} scaffold infoPanel />
        <PrivateRoute exact path="/unit-act-info/:phase/:spaceType/fullscreen" section="unit-act-info" screen="fullscreen" graphType="table" component={UnitInfoPage} scaffold infoPanel />
        <PrivateRoute exact path="/unit-act-info/:phase/:spaceType" section="unit-act-info" screen="landing" graphType="table" component={UnitInfoPage} scaffold infoPanel />
        <PrivateRoute exact path="/unit-act-info/:phase/:spaceType/:tower" section="unit-act-info" screen="fullscreen" graphType="table" component={UnitInfoPage} scaffold infoPanel />
        <PrivateRoute exact path="/checklist-reports/:phase?" section="checklist-reports" screen="landing" graphType="table" component={ChecklistReportsPage} scaffold infoPanel />
        <PrivateRoute exact path="/form-data/:formId?" section="form-data" screen="landing" graphType="table" component={FormDataAggregate} scaffold infoPanel />
        <PrivateRoute exact path="/form-data-write/:unitActivityId/:formId" section="form-data-write" screen="landing" graphType="table" component={FormDataWritePage} scaffold />
        <PrivateRoute exact path="/requests" screen="landing" section="requests" graphType="table" component={RequestsModule} scaffold />
        <PrivateRoute exact path="/requests/:view?" section="requests" graphType="table" component={RequestsModule} scaffold />
        <PrivateRoute exact path="/admin-tools" section="admin-tools" graphType="table" component={AdminLandingPage} scaffold />
        <PrivateRoute exact path="/admin-tools/:apiId?" section="admin-tools" graphType="table" component={AdminLandingPage} scaffold />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  ))
};

export default memo(MainRouter);
