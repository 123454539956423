import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import { INotification } from '../../models/Notifications';
import { NotificationEventTypes } from '../../models/enums';
import { IconCalendarTime, IconDiscountCheck, IconInfoCircle, IconMessage, IconMessageReport, IconProgressAlert, IconSparkles, IconStatusChange, IconTrash, IconUserCheck } from '@tabler/icons-react';
import { Spoiler } from '@mantine/core';

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    width: 400
  },
  media: {
    height: 140,
  },
  title: {
    color: "#585858",
    fontWeight: 600,
    fontSize: "0.8em",
    lineHeight: '1.5em'
  },
  body: {
    color: "#808080",
    fontSize: "0.8rem"
  },
  status: {
    color: "#585858",
    fontWeight: 600,
    fontSize: "0.5em"
  },
  statusDiv: {
    paddingTop: 5
  },
  cardContent: {
    border: "0.01em solid #C9C9C9",
    padding: 10
  },
  genericText: {
    color: "#585858",
    fontSize: "0.7em",
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    overflow: 'hidden',
    '&:hover': {
      whiteSpace: 'normal',
    },
    marginTop: '0.25em'
  }
});

interface INotificationCard extends INotification {
  /* id: string,
   * title: string,
   * body: string,
   * // taskId: string,
   * status: string,
   * eventType: string,
   * // notificationStatus: string,
   * createdAt: string,
   * ticketId: string,
   * taskName?: string, */
  rowCallback: (ticketId: string) => void;
};

const RenderNotificationIcon = (props) => {
  switch (props.eventType) {
    case NotificationEventTypes.TASK_ASSIGNED: return <IconUserCheck 
    color='#0043CE'
    />
    case NotificationEventTypes.COMMENT_ADDED: return <IconMessage 
    color='#E59462'
    />
    case NotificationEventTypes.TICKET_DELETED: return <IconTrash 
    color='#DA1E28'
    />
    case NotificationEventTypes.CORE_FIELD_CHANGED: return <IconStatusChange 
    color='#800080'
    />
    case NotificationEventTypes.TASK_COMPLETED: return <IconDiscountCheck 
    color='#24A148'
    />
    case NotificationEventTypes.MESSAGE_NOTIFICATION: return <IconMessageReport 
    color='#FF832B'
    />
    case NotificationEventTypes.TICKET_CREATED: return <IconSparkles 
    color='#AE3B8B'
    />
    case NotificationEventTypes.DUE_DATE_CROSSED:  return <IconCalendarTime 
    color='#ba4e00'
    />
    case NotificationEventTypes.TASK_PENDING: return <IconProgressAlert 
    color='#E59462'
    />
    default: return <IconInfoCircle 
    color='#6F6F6F'
    />
  }
}

export default function MediaCard({ createdAt, ticketId, rowCallback, comment, ticketTitle, statusName, eventType, notificationCardBody: evtTyp }: INotificationCard) {
  const classes = useStyles();
  return (
    // <Card className={classes.root} onClick={() => rowCallback(ticketId || '1234')}>
    //   <CardActionArea>
    //     <CardContent className={classes.cardContent}>
    //       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    //         <div>
    //           <Typography gutterBottom variant="subtitle2" component="h2">
    //             <span className={classes.title}>{evtTyp} </span>
    //           </Typography>
    //         </div>
    //         <div>
    //           <Typography gutterBottom variant="subtitle2" component="h2">
    //             <span className={classes.title} style={{ fontSize: "0.8em", fontWeight: 100 }}>{moment.utc(createdAt).local().fromNow()}</span>
    //           </Typography>
    //         </div>
    //       </div>

    //       <div style={{ display: 'flex', width: "100%", maxHeight: "4em", overflow: "auto" }}>
    //         {comment.length ?
    //           <div dangerouslySetInnerHTML={{ __html: comment }}></div>
    //           :
    //           null}
    //       </div>
    //       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    //         <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
    //           <div style={{ display: 'flex', flexDirection: 'column' }}>
    //             <span className={classes.status}>Ticket</span>
    //             <span style={{ color: '#808080', fontSize: "0.9em" }}>{ticketTitle}</span>
    //           </div>
    //         </div>
    //         <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
    //           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
    //             <span className={classes.status}>Status</span>
    //             <span style={{ color: '#808080', fontSize: "0.9em" }}>{statusName}</span>
    //           </div>
    //         </div>
    //       </div>
    //     </CardContent>
    //   </CardActionArea>
    // </Card>

    <Card className={classes.root} onClick={() => rowCallback(ticketId || '1234')}>
      <CardActionArea disabled={eventType === NotificationEventTypes.TICKET_DELETED}>
        <CardContent className={classes.cardContent}>

          <div style={{ display: 'flex', width: '100%' }}>

            <div style={{ display: 'flex', flexDirection: 'column', width: '10%' }}>
              <span><RenderNotificationIcon eventType={eventType} /></span>
              {/* <span style={{fontSize: '0.5em'}}>Task assigned</span> */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '90%', flex: 1, flexGrow: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-start', }}>
                  <span className={classes.title}>{evtTyp} </span>
                </div>

                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', }}>
                  <span style={{ fontSize: "0.65em", color: "#585858", fontWeight: 100 }}>{moment.utc(createdAt).local().fromNow()}</span>
                </div>

              </div>

              {comment.length ?
                <div style={{ marginTop: '0.5em', display: 'flex' }}>
                  <Spoiler maxHeight={20} hideLabel={"see less"} showLabel={"see more"}
                    sx={{
                      ["& .mantine-Spoiler"]: { padding: '0.5em', border: '0.01em solid #d9d9d9', },
                      ["& .mantine-Spoiler-content p"]: { margin: 0 },
                      ["& .mantine-Text-root"]: { fontSize: '0.75em' },
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div dangerouslySetInnerHTML={{ __html: comment }}></div>
                  </Spoiler>
                </div>
                : null}

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '0.5em', alignItems: 'flex-start' }}>
                <div style={{ width: '100%', flexGrow: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <span className={classes.status}>Ticket</span>
                    <span className={classes.genericText}>{ticketTitle}</span>
                  </div>
                </div>

                {/* <div style={{ maxWidth: '35%', flexGrow: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0.5em' }}>
                    <span className={classes.status}>Status</span>
                    <span className={classes.genericText}>{statusName}</span>
                  </div>
                </div> */}
              </div>
            </div>

          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
