import React, { useState, useCallback } from 'react';
import BellIconWithCount from '../../components/bell/BellIconWithCount';
import NotificationCard from '../../containers/notification/NotificationCard';
import NotificationNone from '../../containers/notification/NotificationNone';
import { IconButton, Popover } from '@material-ui/core';
import { useStore } from '../../models/ProvideModel';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router';
import { getSnapshot } from 'mobx-state-tree';
import { sortDateFunc } from '../../utils/utils';

const NotificationBellIcon = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const store = useStore();
  const open = Boolean(anchorEl);
  const popoverId = open ? 'notification-popover' : undefined;
  const { search } = useLocation();
  const { location, push } = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const rowCallback = useCallback((ticketId: string) => {
    // Revert to this when going back to url->observable pattern
    const searchP = new URLSearchParams(search);
    searchP.delete('ticketId');
    searchP.append('ticketId', ticketId);
    // TODO: substitute section with the moduleId once sections are implemented.
    push({ ...location, pathname: store.params.toPath({ ...getSnapshot(store.params), section: 'requests', screen: "landing" }), search: searchP.toString() });
    /* store.ticket.setCurrentTicket(ticketId); */
    handlePopoverClose();
  }, [search, location]);

  return (<div>
    <IconButton size="small" onClick={handleClick} aria-describedby={popoverId}>
      <BellIconWithCount count={store.notifications.unread} />
    </IconButton>
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ maxHeight: 500 }}
    >
      {/* .slice().sort((a: any,b: any) => { const b_date = new Date(b.createdAt); const a_date = new Date(a.createdAt);  return b - a}) */}
      {store.notifications && store.notifications.notificationsQ.length ? store.notifications.notificationsQ.slice().sort(sortDateFunc).map(noti =>
        <NotificationCard
          key={noti.id}
          rowCallback={rowCallback}
          statusName={noti.statusName}
          eventType={noti.eventType}
          notificationCardBody={noti.notificationCardBody}
          {...noti}
        />
      ) : <NotificationNone />}
    </Popover>
  </div>)

}

export default observer(NotificationBellIcon);

