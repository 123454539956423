import React, { useEffect, useReducer, useState, forwardRef } from 'react'
import { MultiSelect, MultiSelectValueProps, TextInput } from '@mantine/core';
import { IChan, putAsync } from 'csp-with-ts';
import { observer } from 'mobx-react-lite';
import { IconCalendar, IconCategory, IconFlag, IconTag } from '@tabler/icons-react';
import { TicketStatusCategories } from "../../../models/enums";
import DateTimeInput from '../../../components/FormInputs/DateTimeInput';
import Assignees from '../../../components/assignees/Assignees';
import useStyles, { IStatusStyle } from './CoreFieldsStyles';
import { ITicket } from '../../../models/Ticket';
import FormDropdown from '../../../components/filter/FormDropdown';
import { convertToISO } from '../../../utils/utils';
import Tag from '../../../components/requests/cells/Tag';
import ToggleWatcher from '../../../components/toggleWatcher/ToggleWatcher';

/**Initial sate */
const initialState = ({ coreFields, ...ticket }) => {
  var ret = { delta: {} };
  for (const [id, { isEditable }] of Object.entries<{ [K: string]: any; }>(coreFields)) {
    if (isEditable) {
      ret[id] = ticket[id];
    }
  }
  return ret;
};

/**Reducer */
function reducer(state: { [K: string]: any; }, action: { type: string; payload: { field: string; input: string; type: string }; }) {
  return {
    ...state, [action.payload.field]: action.payload.type === "date" ? action.payload.input == "" ? action.payload.input : convertToISO(action.payload.input) : action.payload.input,
    delta: { field: action.payload.field, value: action.payload.type === "date" ? action.payload.input == "" ? action.payload.input : convertToISO(action.payload.input) : action.payload.input }
  } as { [K: string]: any; };
}
interface IRequestPopupCore {
  ticket: ITicket | undefined;
  priorities: { id: string; name: string; }[];
  coreFields: { [K: string]: any; };
  ch: IChan;
  statuses: { id: string; name: string;[K: string]: string; }[];
  tags: string[];
  userId: string;
  categories: string[];
};

function RequestPopupCore({ ticket, priorities, coreFields, ch, statuses, tags, userId, categories }: IRequestPopupCore) {
  const [state, dispatch] = useReducer(reducer, { ...ticket, coreFields }, initialState);
  const [hideCoreFields, setHideCoreFields] = useState(false)
  const statusStyle: IStatusStyle = { status: (ticket?.statusCategory as TicketStatusCategories) || TicketStatusCategories.INVALID, hideCoreFields }
  const classes = useStyles(statusStyle);
  useEffect(() => {
    putAsync(ch, { ...state.delta });
  }, [state]);
  const showOrHideSelf = () => {
    setHideCoreFields(prev => prev = !prev)
  };
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.coreItem} style={{ display: coreFields["processStatus"].hidden ? 'none' : 'flex' }}>
          {(coreFields["processStatus"].hidden && !coreFields["processStatus"].isMandatory) ?
            null
            : coreFields['processStatus'].isEditable ?
              <FormDropdown
                field={'processStatus'}
                dispatch={dispatch}
                state={state['processStatus'] || (ticket && ticket.status) || ""}
                label={coreFields['processStatus'].name}
                disabled={!coreFields['processStatus'].isEditable}
                options={statuses.slice(1)}
                description={coreFields['processStatus'].description}
                required={false}
                size={'xs'}
                icon={<IconFlag width={'1em'} height={'1em'} />}
                coreField={true}
              />
              :
              <TextInput
                label={coreFields['processStatus'].name}
                value={`${ticket?.statusTitle?.slice(0, 1)?.toUpperCase() || ""}${ticket?.statusTitle?.slice(1) || ""}`}
                readOnly={true}
                size={'xs'}
                style={{ width: '100%' }}
                sx={{
                  ["& .mantine-TextInput-label"]: { fontSize: '0.7em', color: '#585858', fontWeight: 500 },
                  ["& .mantine-TextInput-input"]: { marginTop: '-4px', border: '0', background: '#FFF', paddingRight: '1.8em', width: '100%', '&[data-with-icon]': { paddingLeft: '1.8em' } },
                  ["& .mantine-TextInput-icon"]: { justifyContent: 'flex-start' }
                }}
                icon={<div className={classes.status}></div>}
              />}
        </div>
        <div className={classes.coreItem} style={{ display: coreFields["priority"].hidden ? 'none' : 'flex' }}>
          {(coreFields["priority"].hidden && !coreFields["priority"].isMandatory) ?
            null
            :
            <FormDropdown
              field={'priority'}
              dispatch={dispatch}
              state={state['priority'] || (ticket && ticket['priority']) || ""}
              label={coreFields['priority'].name}
              disabled={!coreFields['priority'].isEditable}
              options={priorities}
              description={""}
              required={false}
              size={'xs'}
              icon={<IconFlag width={'1em'} height={'1em'} />}
              coreField={true}
            />
          }
        </div>
        <div className={classes.coreItem} style={{ display: (coreFields["category"].hidden || !ticket?.category.length) ? 'none' : 'flex' }}>
          {((coreFields["category"].hidden && !coreFields["category"].isMandatory) || !ticket?.category.length) ?
            null
            :
            <FormDropdown
              field={'category'}
              dispatch={dispatch}
              state={state['category'] || (ticket && ticket['category']) || ""}
              label={coreFields['category'].name}
              disabled={!coreFields['category'].isEditable}
              options={categories}
              description={""}
              required={false}
              size={'xs'}
              icon={<IconCategory width={'1em'} height={'1em'} />}
              coreField={true}
            />
          }
        </div>
        <div className={classes.coreItem} style={{ display: coreFields["dueDate"].hidden ? 'none' : 'flex' }}>
          {(coreFields["dueDate"].hidden && !coreFields["dueDate"].isMandatory) ?
            null
            :
            <DateTimeInput
              field={'dueDate'}
              dispatch={dispatch}
              state={state['dueDate'] || (ticket && ticket['dueDate']?.toString()) || ""}
              type='date'
              label={coreFields['dueDate'].name}
              size={'xs'}
              isDueDate={true}
              disabled={!coreFields['dueDate'].isEditable}
              icon={<IconCalendar width={'1em'} height={'1em'} />}
              coreField={true}
            />
          }
        </div>
        <div className={classes.coreItem}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '0.5em !important' }}>
            <span style={{ fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji', fontSize: '0.7em', fontWeight: 500, margin: '4px 0px', color: '#585858', letterSpacing: '0.01071em', lineHeight: 1.55 }}>Assignees</span>
            <Assignees assignees={ticket?.assignee || []} userId={userId} ticket={ticket} ch={ch} />
          </div>
        </div>
        {!!ticket?.canEdit ?
          <div className={classes.coreItem}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '0.5em !important' }}>
              <span style={{ fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji', fontSize: '0.7em', fontWeight: 500, margin: '4px 0px', color: '#585858',  letterSpacing: '0.01071em', lineHeight: 1.55 }}>Tags</span>
              <MultiSelect
                // label="Tags"
                data={tags.map(tag => ({
                  label: tag,
                  value: tag,
                  path: ticket.path,
                  canEdit: ticket.canEdit
                }))}
                value={ticket?.tags.slice() || []}
                onChange={val => {
                  if (val.length > ticket?.tags.length) {
                    ticket?.addTagToTicket(val.slice(-1).join(''));
                  }
                }}
                placeholder="Add tags"
                nothingFound="Nothing found. Start typing to create new tag."
                searchable
                creatable
                getCreateLabel={(query) => `+ Create tag ${query}`}
                onCreate={(query) => query.replace(/\s+/g, '-')}
                valueComponent={ValueComponent}
                size={'sm'}
                sx={{
                  ["& .mantine-MultiSelect-label"]: { fontSize: '0.7em', color: '#585858', fontWeight: 500 },
                  ["& .mantine-MultiSelect-input"]: { borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: '1.8em', width: '95%', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8em' } },
                  ["& .mantine-MultiSelect-icon"]: { justifyContent: 'flex-start' }
                }}
                icon={<IconTag width={'1em'} height={'1em'} />}
              />
            </div>

          </div>
          : <div className={classes.coreItem}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '0.5em !important' }}>
              <span style={{ fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji', fontSize: '0.7em', fontWeight: 500, margin: '4px 0px', color: '#585858',  letterSpacing: '0.01071em', lineHeight: 1.55 }}>Tags</span>
              <MultiSelect
                data={ticket?.tags.map(tag => ({
                  label: tag,
                  value: tag,
                  path: ticket.path,
                  canEdit: ticket.canEdit
                })) || [{ label: 'notFound', value: `No tag linked`, path: undefined, canEdit: false }]}
                value={ticket?.tags.slice() || []}
                valueComponent={ValueComponent}
                size={'sm'}
                disabled={true}
                sx={{
                  ["& .mantine-MultiSelect-label"]: { fontSize: '0.7em', color: '#585858', fontWeight: 500 },
                  ["& .mantine-MultiSelect-input"]: { borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: '1.8em', '&[data-disabled]': { backgroundColor: '#FFF !important', border: 0, opacity: `1 !important`, color: '#585858', cursor: 'default !important' }, '&[data-with-icon]': { paddingLeft: '1.8em' } },
                  ["& .mantine-MultiSelect-icon"]: { justifyContent: 'flex-start' }
                }}
                icon={<IconTag width={'1em'} height={'1em'} />}
              />
            </div>
          </div>
        }
        {/* <div style={{ display: 'flex', marginTop: '1.5em', alignItems: 'center' }}> */}
        {/* <div className={classes.coreItem}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '0.5em !important' }}>
              <span style={{ fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji', fontSize: '0.8525em', fontWeight: 600, color: '#585858', marginTop: '0.5em', marginBottom: '0.5em', letterSpacing: '0.01071em', lineHeight: 1.55 }}>Notify me</span>
              <ToggleWatcher
              toggleState={!!ticket?.watchers.includes(userId)}
              ticket={ticket!}
              />
              </div>
              </div> */}
      </div>
      {/* <div style={{ display: 'flex', marginTop: '1.5em', justifyContent: 'space-between' }}> */}
      {/* </div> */}
    </div>
  )
}

const ValueComponent = ({ label, path, canEdit, onRemove, ...others }: MultiSelectValueProps & { path: string; canEdit: boolean; }) => (
  <div onClick={e => { e.stopPropagation(); }}>
    <Tag
      tag={label}
      path={path}
      actionable={canEdit}
      onRemove={onRemove}
    ></Tag>
  </div>
);

export default observer(RequestPopupCore);
