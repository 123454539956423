import React, { useMemo, PropsWithChildren, memo, useEffect, ReactNode } from 'react';
import {
  MantineReactTable,
  MRT_Table,
  useMantineReactTable,
} from 'mantine-react-table';
import { observer } from 'mobx-react-lite';
import { getParent, onPatch } from 'mobx-state-tree';
import { ITicketModule } from '../../models/Ticket';
import { reaction } from 'mobx';
import { Flex } from '@mantine/core';
const RequestTable = ({ data, children, rowCallback, setRowCount, columns, ToolbarComponent, /* specialFilters, */ gridFilters, currentView }: PropsWithChildren<any>) => {
  /* const columns: MRT_ColumnDef<TicketDetails>[] = useMemo(() => {
   *   const sF = specialFiltersState.reduce((acc, { isActive, filterIds }) =>
   *     isActive ?
   *       [...acc, ...filterIds.filter((f: any) => !acc.includes(f))]
   *       :
   *       acc
   *     , []);
   *   return sF.length ? columnsDef.map(({ id, ...rest }) => sF.includes(id) ?
   *     ({
   *       id,
   *       ...rest,
   *       enableColumnFilter: false
   *     })
   *     : ({ id, ...rest }))
   *     : columnsDef;
   * }, [specialFiltersState]); */

  const table = useMantineReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: false,
    enableFacetedValues: true,
    enableGrouping: false,
    enablePinning: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableStickyHeader: true,
    enableStickyFooter: false,
    enableMultiSort: true,
    initialState: {
      showColumnFilters: true, density: 'xs',
      showGlobalFilter: true,
      /* columnFilters: specialFilters.filter(({ isActive }) => isActive), */
      columnVisibility: { unread: false }
    },
    // Highlighting doesn't work
    /* memoMode: 'cells', */
    enablePagination: false,
    mantinePaginationProps: {
      variant: 'mantine',
    },
    positionToolbarAlertBanner: 'bottom',
    /* renderRowActionMenuItems: () => (
     *   <>
     *     <Menu.Item icon={<IconUserCircle />}>View Profile</Menu.Item>
     *     <Menu.Item icon={<IconSend />}>Send Email</Menu.Item>
     *   </>
     * ), */
    enableToolbarInternalActions: false,
    /* renderToolbarInternalActions: () => (<></>), */
    mantinePaperProps: () => ({
      sx: {
        padding: '0em 1em',
      }
    }),
    mantineTableContainerProps: () => ({
      sx: {
        height: "100%",
      }
    }),
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => { rowCallback(row) },
      sx: {
        cursor: 'pointer',
      },
    }),
    /* renderTopToolbarCustomActions: () => {
     *   return (
     *     <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
     *       {children}
     *     </div>
     *   );
     * }, */
    renderTopToolbarCustomActions: ToolbarComponent,
    mantineTopToolbarProps: () => ({
      sx: {
        display: 'flex',
        "> div": { alignItems: "flex-end" }
      }
    }),
  });

  useEffect(() => {
    // TODO: This might be a very bad idea.
    /* let snap = 0; */
    if (data.length) {
      const columnFilters = (getParent(gridFilters) as ITicketModule).gridSpecialFilters.reduce((acc, { isActive, id, value: v }) => isActive ? [...acc, { id, value: v }] : acc, [])
      table.setState(({ ...rest }) => ({
        ...rest,
        columnFilters
      }));
      columnFilters.forEach(({ id }) => {
        const col = table.getColumn(id);
        col.columnDef.enableColumnFilter = false;
      });
    }
    const p = onPatch(gridFilters, ({ path, value, op }, { value: oldVal }) => {
      if (op === 'replace') {
        const filt = gridFilters.get(path.split('/')[1]).value;
        filt.forEach(({ id, value: v }) => {
          const col = table.getColumn(id);
          if (value) {
            col.columnDef.enableColumnFilter = false;
            col.setFilterValue(() => v);
          }
          else {
            col.columnDef.enableColumnFilter = true;
            col.setFilterValue(() => { return undefined; });
          }
        });
      }
      /* else if (op === 'add' && snap < specialFilters.length - 1) {
       *   snap++;
       * }
       * else if (op === 'add' && snap === specialFilters.length - 1) {
       *   console.log("ninja", (getParent(gridFilters) as ITicketModule).gridSpecialFilters.reduce((acc, { isActive, id, value: v }) => isActive ? [...acc, { id, value: v }] : acc, []), columnFiltersMinusInactive((getParent(gridFilters) as ITicketModule).gridSpecialFilters, []));
       *   table.setState(({ ...rest }) => ({
       *     ...rest,
       *     columnFilters: (getParent(gridFilters) as ITicketModule).gridSpecialFilters.reduce((acc, { isActive, id, value: v }) => isActive ? [...acc, { id, value: v }] : acc, []),
       *   }));
       *   snap = 0;
       * } */
    });
    return () => {
      p();
    }
  }, []);
  useEffect(() => {
    table.resetColumnFilters();
    table.resetSorting();
  }, [currentView]);
  /*
     useEffect(() => {
     table.setState(({ columnFilters: cF, ...rest }) => ({ ...rest, columnFilters: columnFiltersMinusInactive(specialFilters, cF) }));
     }, [specialFilters]);
   */

  useEffect(() => {
    setRowCount(table.getFilteredRowModel().rows.length)
  }, [table.getFilteredRowModel().rows.length])
  return (
    <MantineReactTable
      table={table}
    />
  )
    ;
};

const columnFiltersMinusInactive = (sF: any[], cF: any[]) => {
  /* let cFRef = cF.reduce((acc, { id, value }, ind) => acc[id] ? ({ ...acc, [id]: { ...acc[id], [value]: ind } }) : ({ ...acc, [id]: { [value]: ind } }), {}); */
  let cFRef = cF.reduce((acc, { id, value }) => id === undefined ? acc : ({ ...acc, [id]: Array.isArray(value) ? value : [value] }), {});
  /* for (const { isActive, value } of sF) {
   *   value.forEach(({ id, value: val }) => {
   *     if (isActive) {
   *       if (!(cFRef[id] && cFRef[id][val] !==undefined)) {
   *         cFRef[id] = cFRef[id] || {};
   *         cFRef[id][val] = true;
   *       }
   *     }
   *     else if (!isActive) {
   *       if (cFRef[id] && cFRef[id][val] )) {
   *     delete cFRef[id][val];
   *       }
   *     }
   *   });
   * } */
  for (const { isActive, id, value: val } of sF) {
    if (isActive) {
      cFRef[id] = [val];
    }
    else if (!isActive) {
      if (cFRef[id] !== undefined && cFRef[id].includes(val)) {
        if (cFRef[id].length === 1) {
          delete cFRef[id];
        } else {
          cFRef[id] = cFRef[id].filter(v => v !== val);
        }
      }
    }
  }
  return Object.keys(cFRef).reduce((acc, id) => cFRef[id].length ? [...acc, { id, value: cFRef[id] }] : acc, []);
};

export default observer(RequestTable);
