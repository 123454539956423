import React, { memo } from 'react';
import { TypographyStylesProvider } from '@mantine/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    wordWrap: 'break-word',
    '& h1, h2, h3, h4, h5, h6, p' : {
      margin: 0
    },
    '& hr': {
      margin: '0.5em 0em',
      borderTop: '0.0625rem solid #868e96',
      opacity: 0.3
    }
  },
  content : {
    fontSize: '0.8em'
  }
})

function HTMLContent({ content }) {
  const classes = useStyles();
  return (
    <TypographyStylesProvider className={classes.root}>
      <div
        className={classes.content}
        dangerouslySetInnerHTML={{
          __html: content
        }}
      />
    </TypographyStylesProvider>
  );
}

export default memo(HTMLContent)