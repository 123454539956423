import React, { memo, useRef, useState } from 'react';
import { Dropzone } from '@mantine/dropzone';
import { IImagePickerProps } from './ImagePicker';
import { IconX, IconUpload, IconPhoto, IconEdit, IconNotes, IconDownload, IconHistory, IconVersions, IconDisabled, IconPhotoOff } from '@tabler/icons-react';
import { Group, Text, Divider, Popover, Skeleton, Loader } from '@mantine/core';
import { uploadFileV2, downloadFile, getFileHistory } from '../../api/transactionServer';
import moment from 'moment';
import { makeStyles, Theme } from "@material-ui/core/styles";
import ExceptionOverlay from '../../containers/ticketPopup/others/exception/ExceptionOverlay';
import { notifications } from '@mantine/notifications';

export interface IDropZoneStyleProps {
  disabled : boolean | undefined
}

const useStyles = makeStyles((theme: Theme) => ({
  iconStyles: { width: '1.3em', height: '1.3em', paddingRight: '0.35em', color: '#005eff', },
  iconDivStyles: { display: "flex", justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', '&:hover': { color: '#005eff' } },
  dropozone: (props: { disabled: boolean }) => ({ cursor: props.disabled ? "default" : "pointer", border: props.disabled ? "0.1em solid #ececec" : "0.1em dashed #d9d9d9", '&:hover' : { background: props.disabled ? '#fff' : '#f8f9fa' } })
}));

const FileHistoryCard = ({ file, onDownload }) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex", flexDirection: "column", minWidth: 300, color: '#585858', fontSize: '0.9em', padding: '0.8em' }} key={file.version}>
      <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: '0.2em' }}>
        <span>{file.file_name}</span>
        <div className={classes.iconDivStyles}>
          {/* <img src={require('../../assets/images/figma/Versions.svg')} style={{ paddingRight: '0.2em' }} /><span> Version: {file.version}</span> */}
          <IconVersions className={classes.iconStyles} /><span> Version: {file.version}</span>
        </div>
      </div>
      <Divider size={'xs'} />
      <div style={{ display: "flex", justifyContent: "space-between", paddingTop: '0.2em' }}>
        <span style={{ opacity: 0.5 }}>{moment(file.created_at).add(5, 'h').add(30, 'm').format("DD MMM YYYY hh:mm A")}</span>
        <div className={classes.iconDivStyles} onClick={onDownload}>
          {/* <img src={require('../../assets/images/figma/Download.svg')} style={{ paddingRight: '0.2em' }} /><span>Download</span> */}
          <IconDownload className={classes.iconStyles} /><span>Download</span>
        </div>
      </div>
    </div>
  )
}

const MAX_SIZE = 25 * 1024 ** 2;

// TODO: Handle localstorage
// TODO: Handle version and history
const MantineImageField = ({ label, field, dispatch, state, disabled, required }: IImagePickerProps) => {
  const openRef = useRef<() => void>(null);
  const [history, setHistory] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const dropZoneStyles : IDropZoneStyleProps = { disabled: disabled }
  const classes = useStyles(dropZoneStyles);
  const [error, setError] = useState<{show: boolean; message: string}>();

  const onErrorClose = () => {
    // setError({show: false, message: ""});
  }

  const onChange = (files) => {

    if (files.length && state?.length) {
      setFileLoading(true)
      uploadFileV2({ file: files[0], fileId: state[0].fileId }).then(res => {
        dispatch({ type: 'SET_UPLOAD', payload: { field, type: 'upload', input: [{ ...res.data, createdDate: moment() }] } });
        setFileLoading(false)
      }).catch((error) => {
        console.log(error);
        setFileLoading(false);
        // setError({show: true, message: JSON.stringify(error.message)});
      });
    }
    else if (files.length) {
      setFileLoading(true)
      uploadFileV2({ file: files[0] }).then(res => {
        dispatch({ type: 'SET_UPLOAD', payload: { field, type: 'upload', input: [{ ...res.data, createdDate: moment() }] } });
        setFileLoading(false)
      }).catch((error) => {
        console.log(error);
        setFileLoading(false);
        // setError({show: true, message: JSON.stringify(error.message)});
      });
    }
  };
  const onDownload = () => {
    if (state?.length) {
      downloadFile({ fileId: state[0].fileId, fileName: state[0].fileName });
    }
  };
  const getHistory = () => {
    if (state?.length) {
      getFileHistory({ fileId: state[0].fileId }).then(res => {
        // console.log('mmm', res);
        setHistory(res.data);
      });
    }
  };
  return (
    <div>
      <Group>
        <Text
          weight={500}
          sx={{ marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.8525em', color: '#585858', fontWeight: 600, opactity: 0.8 }}
        >{label}
          {required ? (<span style={{ color: "red", marginLeft: "0.25em" }}>*</span>) : null}
        </Text>
      </Group>
      <Dropzone
        openRef={openRef}
        sx={() => ({
          // height: rem(70)
        })}
        onDrop={(files) => { console.log('accepted files', files, 'size', files[0].size, 'MAX_SIZE', MAX_SIZE); if (files[0].size < MAX_SIZE) onChange(files); }}
        onReject={(files) => { console.log('rejected files', files); 
        // setError({show: true, message: `Maximum file upload size is 25MB`}); 
        notifications.show({ title: 'Failed!', message: `Maximum file upload size is 25 MB.`, color: 'red', icon: <IconX /> })
      }}
        multiple={false}
        name={label}
        disabled={disabled}
        activateOnClick={state?.length ? false : true}
        styles={{ inner: { pointerEvents: "all", cursor: disabled ? "default" : "pointer" } }}
        className={classes.dropozone}
        maxSize={MAX_SIZE}
      >
        {state?.length && !fileLoading ?
          (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: 'space-between', color: '#585858', fontSize: '0.9em' }}>
              <div style={{ display: "flex", justifyContent: 'space-between', paddingBottom: '0.5em' }}>
                <span>{state[0].fileName}</span>
                <span style={{ opacity: 0.5 }}>{moment(state[0].createdDate).format("DD MMM YYYY hh:mm A")}</span>
              </div>
              <Divider />
              <div style={{ display: "flex", justifyContent: 'space-between', paddingTop: '0.5em' }}>
                <div className={classes.iconDivStyles} onClick={getHistory}>
                  {/* <img src={require('../../assets/images/figma/Clock.svg')} style={{ paddingRight: '0.35em' }} /> */}
                  <IconHistory className={classes.iconStyles} />
                  <Popover position="bottom" withArrow shadow="md">
                    <Popover.Target>
                      <span>History</span>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <div>
                        {history && history.map((file: any) => (<FileHistoryCard file={file} onDownload={onDownload} />))}
                      </div>
                    </Popover.Dropdown>
                  </Popover>
                </div>
                {state[0].fileStatus === "draft" ?
                  <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconNotes className={classes.iconStyles} /><span>Draft</span>
                  </div> : null}
                {disabled ? null :
                  <div className={classes.iconDivStyles} onClick={() => { if (openRef?.current) { openRef.current(); } }}>
                    <IconEdit className={classes.iconStyles} /><span>Edit</span>
                  </div>}
                <div className={classes.iconDivStyles} onClick={onDownload}>
                  <IconDownload className={classes.iconStyles} /><span>Download</span>
                </div>
              </div>
            </div>
          )
          : fileLoading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {/* <FileUploadLoadingSkeleton /> */}
              <Loader size={'xs'} />
            </div> : (
              <Group position="center" spacing="xl" style={{ pointerEvents: 'none', alignSelf: 'baseline' }}>
                <Dropzone.Accept>
                  <IconUpload
                    size="1.2em"
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    size="1.2em"
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  { disabled ? <IconPhotoOff size="1.2em" stroke={1.5}/> : <IconPhoto size="1.2em" stroke={1.5} /> }
                </Dropzone.Idle>
                <div>
                  <Text size="xs" inline>
                    { disabled ? `No image attached` :`Drag images here or click to select files`}
                  </Text>
                </div>
              </Group>)
        }
      </Dropzone>
      {/* {error?.show ? <ExceptionOverlay visible={error.show} title={"File Action Error"} closeErrorModal={onErrorClose} message={error.message}/> : null} */}
    </div >
  );
}

export default memo(MantineImageField);
